import Api from './Api'

export default {
    getAllMovieRatings(payload) {
        return Api().post('GetMovieRatings', payload)
    },

    addMovieRatings(payload) {
        return Api().post('AddMovieRatings', payload)
    },
    getSingleRatings(payload){
        return Api().post('AddMovieRatings', payload)
    },


}