<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong
              ><span v-if="form.ID">Update</span
              ><span v-else>Add</span>&nbsp;Censor Ratings</strong
            >
            <small>&nbsp;&nbsp;Form</small>
          </div>
          <b-row>
            <b-col sm="1" class="text-right">
              <label for="CountryName">Country :</label></b-col
            >

            <b-col sm="3">
              <b-form-select
                v-model="form.CountryID"
                id="CountryName"
                :plain="true"
                text-field="CountryName"
                value-field="CountryID"
                :options="$store.getters['common/getAllCountry']"
              >
                <template slot="first">
                  <option :value="'0'">Select Country</option>
                </template>
              </b-form-select>
            </b-col>

            <b-col sm="1" class="text-right"
              ><label for="Title">Title</label></b-col
            >
            <b-col sm="3">
              <b-form-group>
                <b-form-input
                  type="text"
                  required
                  v-model="form.Title"
                  id="Title"
                  placeholder="Enter Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="1" class="text-right"
              ><label for="Rate">Rate</label></b-col
            >
            <b-col sm="3">
              <b-form-group>
                <b-form-input
                  type="text"
                  required
                  v-model="form.rated"
                  id="Rate"
                  placeholder="Enter Rate"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="1" class="text-right"
              ><label for="Description">Description</label></b-col
            >
            <b-col sm="12">
              <b-form-group>
                <vue-editor v-model="form.Description"></vue-editor>
                <!-- <editor
                  placeholder="Enter Description"
                  height="300px"
                  width="100%"
                  :options="defaultOptions"
                  initialEditType="wysiwyg"
                  :initialValue="form.Description"
                  ref="toastuiEditor"
                /> -->
                
                  <!-- @change="onEditorChange"{{form}} -->
                <!-- <b-form-textarea
                  type="text"
                  required
                  :maxlength="max_char"
                  v-model="form.Description"
                  id="Description"
                  placeholder="Enter Description"
                ></b-form-textarea> -->
              </b-form-group>
            </b-col>
            <b-col sm="1" class="text-right"
              ><label for="Status">Rating Image</label></b-col
            >
            <b-col sm="3">
              <b-form-group>
                <b-form-file
                  ref="fileinput"
                  :required="form.ID ? false : true"
                  v-on:change="
                    uploadRatingImg($event.target, 'StaticRatingImage')
                  "
                  accept="image/jpg, image/jpeg, image/png, image/gif, image/avif"
                  :plain="true"
                ></b-form-file>
                <input type="hidden" v-model="form.RatingImage" />
              </b-form-group>
            </b-col>
            <b-col sm="1" class="text-right"
              ><label for="Status">Status</label></b-col
            >
            <b-col sm="3">
              <c-switch
                class="mx-1"
                color="primary"
                v-model="form.IsActive"
                variant="3d"
                label
                v-bind="labelIcon"
            /></b-col>
          </b-row>

          <b-row>
            <b-col sm="5"> </b-col>
            <b-col sm="3">
              <b-button
                @click="SaveRatings()"
                size="sm"
                variant="primary"
                class="ml-1 mr-1"
              >
                <i class="fa fa-dot-circle-o"></i
                ><span v-if="form.ID">Update</span><span v-else>Add</span>
              </b-button>
              <b-button
                @click="resetRatingsForm"
                size="sm"
                variant="success"
                class="mr-1"
              >
                <i class="fa fa-dot-circle-o"></i> Reset
              </b-button>
              <b-button @click="$router.go(-1)" size="sm" variant="danger">
                <i class="fa fa-dot-circle-o"></i> Back
              </b-button>
            </b-col>
            <b-col sm="4"> </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CensorRatingService from "@/services/CensorRatingService";
import UploadService from "@/services/UploadService";
import { Switch as cSwitch } from "@coreui/vue";
// import "@toast-ui/editor/dist/toastui-editor.css";
// import { Editor } from "@toast-ui/vue-editor";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      defaultOptions: {
        minHeight: "200px",
        language: "en-US",
        useCommandShortcut: true,
        usageStatistics: true,
        hideModeSwitch: false,
        toolbarItems: [
          ["heading", "bold", "italic", "strike"],
          ["hr", "quote"],
          ["ul", "ol", "task", "indent", "outdent"],
          ["table", "image", "link"],
          ["code", "codeblock"],
          ["scrollSync"],
        ],
      },
      BaseURL: "",
      max_char: 256,
      form: {
        CountryID: 0,
        ID: "",
        LanguageID: "1",
        RatingImage: null,
        rated: "",
        Title: "",
        Description: "",
        IsActive: true,
        Flag: "2",
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  components: {
    cSwitch,
    VueEditor,
    // Editor,
  },
  computed: {},
  watch: {},
  beforeMount() {
    if (this.$route.params.ID && !isNaN(this.$route.params.ID)) {
      this.form.ID = this.$route.params.ID;
      this.getSingleRatings(this.form.ID);
    }
    this.$store.dispatch("common/setAllCountry");

  },
  mounted: function () {
  },
  methods: {
    uploadRatingImg(image, name) {
      console.log("uploadRatingImg");
      this.BaseURL = "";
      let refs = this.$refs;
      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = image.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = async () => {
          var formdata = new FormData();
          formdata.append("assets/images/movies/banners", image.files[0]);
          console.log("formdata: ", formdata);
          UploadService.uploadFiles(formdata)
            .then((response) => {
              if (response.data.Status) {
                this.form.RatingImage = "";
                this.form.RatingImage = response.data.Path;
                // alert(this.form.RatingImage)
                this.BaseURL = response.data.BaseURL;
                // alert(this.form.RatingImage)
              }
            })
            .catch((error) => {
              console.log("Catch on AddStaticImages-onUpload Error: ", error);
              self.showMessageSnackbar(
                "Oops! Something went wrong. kindly contact your administrator"
              );
            });
        };
      }
    },
    // getHtml() {
    //   let html = this.$refs.toastuiEditor.invoke("getHTML");
    //   this.form.Description = html;
    //   console.log(html);
    // },
    // onEditorChange(Description) {
    //   this.$refs.toastuiEditor.invoke('setHTML', Description);
    // },
    SaveRatings() {
      // this.getHtml();

      console.log("this.form=>", this.form);
      this.form.LanguageID = this.form.LanguageID
        ? parseInt(this.form.LanguageID)
        : "";
      if (
        this.form.CountryID == "" ||
        this.form.CountryID == 0 ||
        this.form.LanguageID == "" ||
        this.form.LanguageID == 0 ||
        this.form.RatingImage == "" ||
        this.form.rated == "" ||
        this.form.Title == "" ||
        this.form.Description == ""
      ) {
        this.showMessageSnackbar("Please Fill All Details");
        return false;
      } else {
        if (this.form.ID == "") {
          this.form.Flag = 1;
        } else if (this.form.ID != "") {
          this.form.Flag = 2;
          this.form.ID = this.form.ID ? parseInt(this.form.ID) : "";
        }

        CensorRatingService.addMovieRatings(this.form)
          .then((response) => {
            const { data } = response;
            if (data.Status) {
              this.showMessageSnackbar(data.Message);
              this.$router.push({
                path: "/ratings/censor-ratings",
              });
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on Banner-getBannerByID Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. Kindly contact your administrator"
            );
          });
      }
    },

    getSingleRatings(ID) {
      let payload = { CountryID: 113, ID: ID };
      CensorRatingService.getAllMovieRatings(payload)
        .then((response) => {
          const { data } = response;
          console.log("response", response);
          if (data.Status && data.Rating.length > 0) {
            // this.form = data.Rating[0];
            this.form.ID = data.Rating[0].ID;
            this.form.CountryID = data.Rating[0].CountryID;
            this.form.rated = data.Rating[0].rated;
            this.form.LanguageID = data.Rating[0].LanguageID;
            this.form.RatingImage = data.Rating[0].RatingImage;
            this.form.Description = data.Rating[0].Description;
            this.form.Title = data.Rating[0].Title;
            this.form.IsActive = data.Rating[0].IsActive == 1 ? true : false;
            this.form.Flag = 2;
            // this.onEditorChange(this.form.Description);
          } else {
            this.showMessageSnackbar(data.message);
          }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetRatingsForm: function () {
      this.form = {
        CountryID: 0,
        ID: "",
        LanguageID: "1",
        RatingImage: "",
        rated: "",
        Title: "",
        Description: "",
        IsActive: "1",
        Flag: "2",
      };

      this.BaseURL = "";
      this.$refs["fileinput"].reset();
    },

    // Show Confirmmation Dialog
    handleModalOkay: function (evt) {
      // // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function (payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function (ref) {
      this.$refs[ref].show();
    },
    closeModal: function (ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style></style>
